<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'text'
  },
  label: String,
  name: {
    type: String,
    default: ""
  },
  options: [Map, Array],
  map: Array,
  multiple: Boolean,
  withSearch: Boolean,
  error: String,
  preset: [Array,String,Number],
  grouped: Boolean,
  returnSimpleValue: Boolean,
  inputDisabled: Boolean,
})

const emits = defineEmits(['change'])
const value = defineModel({default: () => []})
const isValidValue = computed(() => {
  if(typeof value.value === "undefined") return false;
  if(value.value === null) return false;
  return !(typeof value.value.pop !== "undefined" && !value.value.length);
})

const maxOptionWidth = ref(0)
const refOptions = ref();
const refScrollBar = ref();

const open = ref(false);
const opened = ref(false);
const values = ref(new Map);
const content = computed(() => {
  if (props.returnSimpleValue && !props.multiple) {
    return values.value.get(value.value);
  } else {
    return Array.from(values.value.valuesByKeys(value.value || [])).join(", ")
  }
});
const searchString = ref("")

const optionsHeight = computed(() => (props.options ? Math.min(props.options.size, 8) : 0) * 34);
const searchHeight = computed(() => props.withSearch ? 52 : 0);
const dropDownHeight = computed(() => optionsHeight.value + searchHeight.value + 12);

const reset = () => {
  values.value.clear();
  if (props.returnSimpleValue && !props.multiple) {
    value.value = null;
  } else {
    value.value = [];
  }
  nextTick(() => emits('change', value.value, true));
}

const setValue = (key, group = null) => {
  values.value.clear();
  if (!group) {
    values.value.set(key, listOptions.value.get(key));
  } else {
    values.value.set(key, listOptions.value.get(group).get(key));
  }
  if (props.returnSimpleValue && !props.multiple) {
    value.value = values.value.keys().next().value
  } else {
    value.value = Array.from(values.value.keys())
  }
  open.value = false;
  nextTick(() => emits('change', value.value, false));
}

const manageValue = (key, add, group = null) => {
  if (add && !values.value.has(key)) {
    if (!group) {
      values.value.set(key, listOptions.value.get(key));
    } else {
      values.value.set(key, listOptions.value.get(group).get(key));
    }
  }
  if (!add && values.value.has(key)) {
    values.value.delete(key);
  }
  value.value = Array.from(values.value.keys())
  nextTick(() => emits('change', value.value, false));
}

onMounted(() => {
  if (typeof value.value !== 'undefined' && value.value !== null) {
    if(props.returnSimpleValue && !props.multiple) {
      setValue(value.value);
    } else {
      value.value.forEach(k => manageValue(k, true))
    }
  }
})

defineExpose({
  reset
})

const toggleDropDown = () => {
  if (!open.value) searchString.value = "";
  // if(!open.value) {
  //   maxOptionWidth.value = 0;
  //   const allOptions = refOptions.value.querySelectorAll(".👾");
  //   allOptions.forEach((option) => {
  //     const rect = option.getBoundingClientRect();
  //     const width = rect.width;
  //     if(width > maxOptionWidth.value) maxOptionWidth.value = width;
  //   });
  // }
  open.value = !open.value;

  if (!open.value) opened.value = false;
}
const closeDropDown = () => {
  searchString.value = "";
  open.value = false;
  opened.value = false;
}

const refreshScrollBar = () => {
  refOptions.refresh = new Date();
}

const listOptions = computed(() => {
  const op = new Map();
  const resultOps = new Map();
  if (!props.grouped) {
    if (props.map) {
      const [key, value] = props.map;
      props.options.forEach(item => op.set(item[key].toString().toLowerCase(), item[value]));
    }

    const options = props.map ? op : props.options;
    options.forEach((value, key) => {
      if (!searchString.value || value.toLowerCase().includes(searchString.value.toLowerCase())) {
        resultOps.set(key, value)
      }
    })
  } else {
    const groups = props.options;
    groups.forEach((options, group) => {
      const groupOps = new Map();
      options.forEach((value, key) => {
        if (!searchString.value || value.toLowerCase().includes(searchString.value.toLowerCase())) {
          groupOps.set(key, value)
        }
      })
      resultOps.set(group, groupOps);
    })
  }
  return resultOps;
})

watch(
  () => props.preset,
  (newValue) => {
    nextTick(() => {
      if (typeof newValue === "undefined" || newValue === null) return;
      if(typeof newValue.pop === "undefined") {
        // if(newValue) {
        //   values.value.clear();
        //   value.value = null;
        // }  else {
          setValue(newValue);
        // }
      } else {
        newValue = newValue.filter(i => listOptions.value.has(i));
        if (!newValue.length) return;
        values.value.clear();
        value.value = newValue;
        newValue.forEach(k => manageValue(k, true))
      }
    })
  },
  {deep: true}
)

</script>

<template>
  <div class="relative w-full h-12 pt-3 border-b border-b-gray-500"
       :class="{'border-b-error-red':error}"
       v-click-outside="closeDropDown">
    <div :class="{'opacity-15': inputDisabled}" class="relative w-full h-9 text-sm font-light focus:outline-none rounded-none bg-transparent text-azure placeholder-transparent pt-2 select-none"
         @click="toggleDropDown"
    >
      <div class="flex items-center gap-1">
        <div class="grow whitespace-nowrap overflow-ellipsis overflow-hidden">{{ content }}</div>
        <div v-if="content">
          <div class="rounded-full bg-gray-200 w-3 h-3 flex justify-center items-center p-0.5"
               @click.stop="reset">
            <IconClose />
          </div>
        </div>
        <div>
          <IconChevronDown class="transition-all duration-500 will-change-transform"
                           :class="{'rotate3D-x-180':open}"
          />
        </div>
      </div>
    </div>
    <label class="absolute h-3 text-gray-500 font-extralight !leading-none transition-all pointer-events-none select-none text-sm top-6 safari-fix-position"
           :class="[{'!text-[10px] !top-0.5': isValidValue},{'opacity-0':error && isValidValue}, { 'opacity-15': inputDisabled}]"
    >
      {{ label }}
    </label>
    <div v-show="error" class="absolute text-[10px] left-0 top-0.5 h-3 text-error-red font-extralight !leading-none">{{ error }}</div>
    <div class="absolute overflow-hidden min-w-full shadow-box bg-white z-40 h-0 will-change-transform"
         :class="[{'transition-all duration-300':open, '!h-auto':open}]"
         :style="[open ? {'height': dropDownHeight +'px'} : '']"
         @transitionend="opened = open"
    >
      <div class="w-full">
        <div v-if="props.withSearch" class="px-3 pt-0.5">
          <InputText label="Wyszukaj"
                     v-model="searchString"
                     @input="refreshScrollBar"
          />
        </div>
        <div class="flex justify-between">
          <div ref="refOptions" class="grow flex flex-col gap-3.5 p-3 pr-0 overflow-auto custom-scrollbar md:overflow-hidden select-none max-h-72  w-fit">
            <template v-if="!grouped">
              <template v-for="[key, caption] in listOptions">
                <div class="min-h-5 max-h-5 w-full font-extralight whitespace-nowrap pr-4">

                  <InputCheckBox v-if="props.multiple"
                                 @change="(checked) => manageValue(key, checked)"
                                 :checked="!!value.find(k => k === key)"
                                 fullClick
                                 bigger
                  >{{ caption }}
                  </InputCheckBox>
                  <div v-else
                       class="text-sm cursor-pointer"
                       @click="setValue(key)"

                  >{{ caption }}
                  </div>

                </div>
              </template>
            </template>
            <template v-else>
              <template v-for="[group, options] in listOptions">
                <div class="font-extralight text-xs text-gray-500 border-b border-b-gray-100">{{ group }}</div>
                <template v-for="[key, caption] in options">
                  <div class="min-h-5 max-h-5 w-full font-extralight overflow-hidden">

                    <InputCheckBox v-if="props.multiple"
                                   @change="(checked) => manageValue(key, checked)"
                                   :checked="!!value.find(k => k === key)"
                                   fullClick
                                   bigger
                    >{{ caption }}
                    </InputCheckBox>
                    <div v-else
                         class="text-sm cursor-pointer"
                         @click="setValue(key, group)"

                    >{{ caption }}
                    </div>

                  </div>
                </template>
              </template>
            </template>
            <template v-if="!listOptions.size">
              <div class="font-extralight text-sm text-gray-400">( pusta lista )</div>
            </template>
          </div>
          <div class="py-3">
            <ScrollBarVertical ref="refScrollBar" :scrolledElement="refOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .custom-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .custom-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
