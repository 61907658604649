<script setup>

const {onMouseUp, onMouseMove} = useMouseEvents();

const props = defineProps({
  scrolledElement: Object
});

const emits = defineEmits(['scroll']);

let handleDragOffsetY = 0;

const refTrack = ref();
const refHandle = ref();
const offsetTop = ref(0);
const dragging = ref(false);

const handleLength = ref(0);
const trackLength = ref(0);
const visible = ref(true);

const calculateRatio = () => {
  const height = props.scrolledElement.getInnerHeight();
  const scrollHeight = props.scrolledElement.scrollHeight;
  if (!scrollHeight) return 0;
  return height / scrollHeight;
}
const scrollTo = (top) => {
  const trackRect = refTrack.value.getBoundingClientRect();
  const handleRect = refHandle.value.getBoundingClientRect();
  const maxTrackSize = trackRect.height - handleRect.height;

  top = Math.min(Math.max(top, 0), maxTrackSize);
  offsetTop.value = top;
  const ratio = calculateRatio() || 1;
  props.scrolledElement.scrollTo(0, top / ratio)
}

const startDrag = (event) => {
  const {y} = refHandle.value.getBoundingClientRect();
  handleDragOffsetY = (event.pageY || event.touches[0].pageY) - y;
  dragging.value = true;
}
const drag = (event) => {
  if (!dragging.value) return;
  const pageY = event.pageY || event.touches[0].pageY;

  const trackRect = refTrack.value.getBoundingClientRect();
  let top = pageY - trackRect.y - handleDragOffsetY;

  scrollTo(top);
}

const init = () => {
  if (!props.scrolledElement) return;
  const ratio = calculateRatio();
  const {height} = props.scrolledElement.getBoundingClientRect();
  nextTick(() => {
    trackLength.value = refTrack.value.getBoundingClientRect().height;
    handleLength.value = Math.round(trackLength.value * ratio);
    visible.value = height < props.scrolledElement.scrollHeight;
  });
}
const attachEvents = () => {
  const hasEventsAttached = props.scrolledElement.connected || false;
  if (hasEventsAttached) return;
  props.scrolledElement.connected = true;
  const wheelEvent = () => {
    props.scrolledElement.addEventListener("wheel", (event) => {
      event.preventDefault();

      const offsetY = offsetTop.value;
      let top = offsetY + event.deltaY;

      scrollTo(top)
    })
  }

  const dragEvent = () => {
    props.scrolledElement.addEventListener("touchstart", (event) => {
      props.scrolledElement.dragging = true;
      props.scrolledElement.startY = event.touches[0].pageY;
      props.scrolledElement.startScrollTop = props.scrolledElement.scrollTop;
    }, {passive: true})

    props.scrolledElement.addEventListener("touchmove", (event) => {
      event.preventDefault();
      if (!props.scrolledElement.dragging) return;
      const ratio = calculateRatio();
      const top = props.scrolledElement.startScrollTop + (props.scrolledElement.startY - event.touches[0].pageY);
      const {height: maxHeight} = refTrack.value.getBoundingClientRect();

      props.scrolledElement.scrollTop = top;
      offsetTop.value = Math.min(Math.max(top * ratio, 0), maxHeight - handleLength.value);
    }, {passive: true});
  }

  const resizeEvent = () => {
    const ob = new MutationObserver(() => {
      init();
    })

    ob.observe(props.scrolledElement, {
      attributes: true,
      childList: true,
      subtree: false
    });
  }

  resizeEvent();
  wheelEvent();
  dragEvent();
}

onMouseUp(() => {
  if(props.scrolledElement) {
    props.scrolledElement.dragging = false;
  }
  dragging.value = false
});

watch(
  () => props.scrolledElement,
  (element) => {
    if (element) {
      init();
      attachEvents();
    }
  }
);

onMounted(() => {
  init();
})

onMouseMove(drag)
</script>

<template>
  <div ref="refTrack" class="relative h-full w-8" :class="[{'opacity-0 -mr-8':!visible}]">
    <div class="absolute top-0 w-0.5 mx-4 h-full bg-gray-200"></div>
    <div ref="refHandle"
         class="group absolute left-0 w-8"
         :style="[{top: offsetTop + 'px'},{'height': handleLength + 'px'}]"
         @mousedown.passive.prevent="startDrag"
         @touchstart.passive="startDrag">
      <div class="w-0.5 h-full mx-4 bg-azure rounded-full group-hover:mx-[13px] group-hover:w-2 transition-all"></div>
    </div>
  </div>
</template>
